<template>
	<div id="playlist">
        <router-link v-for="video in videos" class="thumbContainer videoLoad" :class="{ playing: currentVid==video }" :key="video.id" :to="video.id" :style="{ backgroundImage: 'url(https://img.youtube.com/vi/' + video.id + '/mqdefault.jpg)' }">
            <span class="thumbtitle">
                <span :style=newness(video)><em>✨new!✨</em><br :style=isCurrent(video)[0] /></span>
                <span :style=isCurrent(video)[1]><em>now playing ▶️</em><br/></span>
                {{ video.title }}
            </span>
        </router-link>
	</div>
</template>

<script>
export default {
    name: 'playlistItem',
    props:  ['videos','currentVid','today'],
    methods: {
        newness(video) {
            // Based on date added to site, not date uploaded to YT
            if (Math.abs(new Date(video.date_subbed) - this.today)/(24*60*60*1000) < 7) {
                return 'display:inline;font-variant:small-caps'
            }
            else {
                return 'display:none'
            }
        },

        isCurrent(video) {
            if (video == this.currentVid)  {
                return ['display:none;','display:inline;font-variant:small-caps']
            }
            else {
                return ['display:inline','display:none']
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.router-link-active {
  text-decoration: none;
  border: none;
}

.videoLoad {
    text-decoration: none;
  border: none;
}

.thumbContainer:hover .thumbtitle {
    display:block;
    color: white;
    text-decoration: none;
}

.playing .thumbtitle {
    display:block;
    font-weight: bold;
    color:#ffd9d9;
    text-decoration: none;
}

.thumbContainer:hover {
    opacity: 1;
}

.thumbContainer.playing {
    opacity: 1;
}

@media screen and (min-width: 1000px) {
    .thumbContainer {
        background-size: contain;
        background-repeat: no-repeat;
        width: 16vw;
        /* max-width: 100%; */
        margin: 2.5%;
        flex-direction: column;
        /* flex-shrink:0; */
        height: 9vw;
        opacity: .75;
    }

    .thumbtitle {
        display: none;
        font-family: sans-serif;
        background-color:rgba(0,0,0,0.7);
        text-align: center;
        font-size: 1vw;
        /* width: 16.85vw; */
        max-width: 100%;
        position: relative;
        top:3vw;
    }

}

@media screen and (max-width: 999px) {   
    .thumbContainer {
        background-size: contain;
        background-repeat: no-repeat;
        width: 32vh;
        padding: 0.25rem;
        min-width: 20vh;
        flex-shrink:0;
        height: 17vh;
        opacity: .75;
    }

    .thumbtitle {
        display: none;
        font-family: sans-serif;
        background-color:rgba(0,0,0,0.7);
        text-align: center;
        font-size: 1.5vh;
        text-align: center;
        width: 31vh;
        max-width: 100%;
        position: relative;
        top:4.75vh;
        left:-.55vh;
        padding: 0.25rem;
        max-height: 100%;
    }
}
</style>
