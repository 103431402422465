<template>
    <div id="container">
        <div id="playlistcontainer">
            <div id="playlistsorter">
                SORT BY:
                <select @change="sortvideos">
                    <option value="sub" selected>Date Subbed</option>
                    <option value="pub">Date Uploaded</option>
                    <option value="name">Title</option>
                </select>
            </div>
            <playlistItem v-if="currentVidID !== null" :current-vid="currentVid" :today="today" :videos="videos"></playlistItem>
        </div>
        <playingVid v-if="currentVidID !== null" :videos="videos" :current-vid="currentVid"></playingVid>
        <notifications classes="invalid"/>
    </div>
</template>

<script>
import playlistItem from './components/playlistItem.vue';
import playingVid from './components/playingVid.vue';

export default {
  name: 'SnowTube',
  components: {
    playlistItem,
    playingVid
  },
	data() {
		return {
            videos: {},
			currentVidID: null,
			today: new Date(),
            sortorder: "sub"
		}
    },
    computed: {
        currentVid() {
            return this.videos[this.currentVidID]
        }
    },
	methods: {
		sortvideos(event) {         
            switch(event.target.value) {
                case "sub": {this.sorter("date_subbed"); break;}
                case "name": {this.sorter("title"); break;}
                case "pub": {this.sorter("date_uploaded"); break;}
            }

        },
        sorter(key) {
            if (key == "title") {
               this.videos = Object.entries(this.videos)
                                    .sort((a,b)=> {
                                        if (a[1]["title"].replace(/[^a-zA-Z0-9]/g,'') < b[1]["title"].replace(/[^a-zA-Z0-9]/g,'')) { return -1 }
                                        if (a[1]["title"].replace(/[^a-zA-Z0-9]/g,'') > b[1]["title"].replace(/[^a-zA-Z0-9]/g,'')) { return 1 }
                                        return 0                                       
                                        })
                                    .reduce((obj,item)=>{obj[item[0]] = item[1]; return obj;},{}); 
            }
            else {
                this.videos = Object.entries(this.videos)
                .sort((a,b)=> { 
                    if (a[1][key]<b[1][key]) { return 1 }
                    if (a[1][key]>b[1][key]) { return -1}
                    return 0
                    })
                .reduce((obj,item)=>{obj[item[0]] = item[1]; return obj;},{});
            }
        },
        checkRoute() {
            if (Object.keys(this.videos).indexOf(this.$route.params.id)>=0) {
                this.currentVidID = this.videos[this.$route.params.id].id;
            }
            else {
                this.currentVidID = Object.values(this.videos)[0].id;
                if (!(this.$route.params.id === undefined)) {
                    this.$notify({  title: '⛄ Invalid video ID ⛄',text: 'Sorry, we don\'t have that video yet.'})
                }
            }
            this.sorter("date_subbed");
        }	
    },
	created() {
        fetch('/.netlify/functions/get_playlist')
            .then(
                response => response.json()
            )
            .then(
                data => {
                    for (const video in data) {
                        this.videos[data[video].id]=data[video]
                    }
                }
            )
            .finally(() => this.checkRoute())
    },
    watch: {
        $route(to) {
            if (Object.keys(this.videos).indexOf(to.params.id)>=0) {
                this.currentVidID = this.videos[to.params.id].id;         
            }
            else {
                this.$notify({  title: '⛄ Invalid video ID ⛄',
                                text: 'Sorry, we don\'t have that video yet.'})
            }
        }
    }
}
</script>

<style>
@font-face {
    font-family: 'pacificoregular';
    src: url('./assets/fonts/pacifico-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/pacifico-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    background-color: #ffd9d9;
    background-image: url("./assets/iwasakumark.png");
    background-repeat:repeat;
    background-size:15%;
    font-family: sans-serif;
    }

h1 {
    font-family: 'pacificoregular', cursive;
    color: white;
    text-align: center;
    filter: drop-shadow(0 0 0.5rem #ff4d4d);
}

#description {
    font-size:75%;
    white-space: pre-line;
}

#footer {
    text-align: center;
    font-size:80%;
    background-color: rgba(255, 217, 217,.7);
    margin: .5rem auto;
    font-size:65%;
}

#playlistsorter {
    max-height:5%;
    flex-wrap: nowrap;
    font-size: .75rem;
    text-align: center;
    max-width: 100%;
}

#playlistsorter > select {
    font-size: .75rem;
}

.youtube-external-subtitle span { 
    color:pink;
    font-weight: bold;
}

/* Notifications */
.invalid {
    padding: 10px !important;
    margin: 0 5px 5px !important;
    
    font-size: 12px !important;
    
    color: #FFFFFF !important;
    background-color: #ca7979 !important;
    border-left: 5px solid #e8b1b1 !important;
}

@media screen and (min-width: 1000px) {
    #container {
        width: 90%;
        height:78vh;
        margin: 0 auto;
        display: flex;
        background-color: #c6e3fa;
        border-radius: 1rem;    
        overflow: hidden;
        filter: drop-shadow(0 0 0.5rem #91cfff);
    }

    #playlistcontainer {
        flex-basis: 20%;
        padding: 1.5%;
    }

    #playlist {
        max-height: 95%;
        display: flex;
        flex-wrap:wrap;
        overflow: auto;
        padding: 2%;
    }

    .youtube-external-subtitle span { 
        font-size: 1rem;
    }
}

@media screen and (max-width: 999px) {
    #container {
        width: 90%;
        min-height: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        background-color: #c6e3fa;
        border-radius: 1rem;
        filter: drop-shadow(0 0 0.5rem #91cfff);
    }

    #playlist {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        margin: 0 auto;
        max-width: 95%;
    }

    #playlistcontainer {
        max-width: 100%;
    }
    
    .youtube-external-subtitle span { 
        font-size: max(2vw,2vh);
    }
}
</style>