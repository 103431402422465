<template>
	<div id="videocontainer" :key="$route.fullPath">
	<iframe id="video" :src="embedURL" :class="currentVid.id"></iframe>
		<div id="videoinfo">
			<div id ="topcontent">
				<div id="meta">
					<h2 id="title">{{ currentVid.title }}</h2>
					<span class="meta-item"><strong>Uploaded:</strong> {{ upJapan }} 🇯🇵</span> 
					<span class="meta-item"><strong>Subbed:</strong> {{ pubUS }} 🇺🇸</span>
					<span class="meta-item series" v-for="(series, index) in currentVid.series" :key="index">#{{ series }}</span>
				</div>
				<div id="dlbut" v-if="currentVid.dllink">
					<h4><a :href="currentVid.dllink" target="_blank">DOWNLOAD ON LJ 🔒</a></h4>
				</div>
			</div>
			<p id="description"> {{ currentVid.description }}</p>
		</div>
	</div>
</template>

<script>
import YoutubeExternalSubtitle from '../assets/youtube.external.subtitle.js'
import parser from '../assets/subtitles.parser.min.js'

// Import day.js for date display
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
	name: 'playingVid',
	props: ['currentVid','videos'],
	computed: {
		embedURL() {
			return "https://www.youtube.com/embed/"+this.currentVid.id+"?enablejsapi=1&html5=1&playsinline=1&fs=0"
		},
		pubUS() {
			return dayjs.tz(this.currentVid.date_subbed,"America/New_York").format('ddd MMM D YYYY')
		},
		upJapan() {
			return dayjs.tz(this.currentVid.date_uploaded,"Asia/Tokyo").format('ddd MMM D YYYY')
		}

	},
    methods: {
		addSubs(video) {
			let anid = video.id;
			this.loadSRT(video.subfile[0].url,function(subtitles) {
				/* eslint-disable no-unused-vars */
				var youtubeExternalSubtitle = new YoutubeExternalSubtitle.Subtitle(document.getElementsByClassName(anid)[0],subtitles)
				/* eslint-enable no-unused-vars */
			})
		},

		loadSRT(url,callback) {
			fetch(url)
			.then (
				response => response.text())
			.then(subfile => {
					var subtitles = parser.fromSrt(subfile, true);
					for (const i in subtitles) {
						subtitles[i] = {
							// Edited to add an offset since subs were coming in a little late
							start : subtitles[i].startTime / 1000 - 0.4,
							end   : subtitles[i].endTime / 1000 - 0.4,
							text  : subtitles[i].text
						};
					}
				callback(subtitles);
				})
			.catch()
		}
	},
    mounted() {
		this.addSubs(this.currentVid);
	},
	updated() {
		this.addSubs(this.currentVid);
	}

}
</script>

<style scoped>
iframe {
    border:none;
}

#topcontent {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
}

#dlbut {
	/* Match styling to notifications */
	padding: 0 10px;
	margin: 5px;
	font-size: 12px;
	color: #ffffff;
    background-color: #ca7979;
	width: 130px;
	text-align: center;
}

#dlbut a {
	color: white;
	text-decoration: none;
}

h2 {
	margin-bottom: .2rem
}

.meta-item {
	white-space: nowrap;
	font-size: 11px;
	margin-right: 1rem;
}

.series {
	color: rgb(6, 95, 212);
}

@media screen and (min-width: 1000px) {
    #videocontainer {
        flex-basis: 75%;
        padding: 2.5%;
        overflow: hidden;
    }

    #video {
        width: 100%;
        height: 55vh;
    }

    #videoinfo {
        height: 20vh;
        overflow: auto;
        margin-bottom: 1rem;
    }
}


@media screen and (max-width: 999px) {
    #videocontainer {
        display: block;
        overflow: hidden;
        padding: 2.5%;
    }

    #video {
        width:100%;
        height: 75%;
        height: 70vh;
    }
}
</style>