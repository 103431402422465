import Vue from 'vue'
import Notifications from 'vue-notification'
Vue.use(Notifications)
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import App from './App.vue'
import playingVid from './components/playingVid.vue'

import VueGtag from "vue-gtag";

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/:id', component: playingVid},
    { path: '/', component: playingVid}
  ]
})

Vue.use(VueGtag, {
  config: {id: "UA-176785906-1"}
}, router);

new Vue({
  render: h => h(App),
  router
}).$mount('#container')